import React, { useState, useEffect, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { makeRequest } from '../../Services/APIService';
import APIUrlConstants from '../../Config/APIUrlConstants';
import Loading from '../Widgets/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { gaEvents, httpStatusCode } from '../../Constants/TextConstants';
import useAnalyticsEventTracker from '../../Hooks/useAnalyticsEventTracker';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { downloadFile, formatFileSize } from '../../Utilities/AppUtilities';
import Alerts from '../Widgets/Alerts';
import { useSelector } from 'react-redux';

export const getNextPrevTicketNo = (ticketList, id) => {
  if (!ticketList || ticketList.length === 0) {
    return { nextTicket: null, prevTicket: null };
  }
  const currentTicketIndex = ticketList.findIndex((a) => id === a.ticketNo);
  if (currentTicketIndex === -1) {
    return { nextTicket: null, prevTicket: null };
  }
  const nextTicket = currentTicketIndex + 1 < ticketList.length ? ticketList[currentTicketIndex + 1].ticketNo : null;
  const prevTicket = currentTicketIndex - 1 >= 0 ? ticketList[currentTicketIndex - 1].ticketNo : null;

  return { nextTicket, prevTicket };
};

export default function ViewTicket() {
  const navigate = useNavigate();
  const { ticketList } = useSelector((state) => state.TicketReducer);
  const [showAlert, setShowAlert] = useState(false);
  const [alertVarient, setAlertVarient] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { id } = useParams();
  const { buttonTracker } = useAnalyticsEventTracker();
  const [ticketDocuments, setTicketDocuments] = useState([]);
  const nextPrevTicket = useMemo(() => getNextPrevTicketNo(ticketList, id), [ticketList, id]);

  const fetchTicketDetails = async () => {
    setLoading(true);
    const { 0: statusCode, 1: resp } = await makeRequest(
      `${APIUrlConstants.VIEW_TICKET}/${id}?customerNo=${localStorage.getItem('orgNo')}`,
    );
    if (statusCode === httpStatusCode.SUCCESS) {
      setData({
        ...resp.data[0],
        description: resp.data[0].description.replace(/\n/g, '<br/>'),
        solutionProvided: resp.data[0].solutionProvided.replace(/\n/g, '<br/>'),
      });
      setTicketDocuments(resp.data[0].documentList);
      setLoading(false);
    } else {
      setAlertVarient('danger');
      setAlertMessage('There was a problem with our ticketing service. Please try again later');
      setShowAlert(true);
      setTimeout(() => {
        navigate('/tickets');
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleDownload = (documentNo) => {
    const document = ticketDocuments.find((a) => a.documentNo === documentNo);
    const { fileName, encodedDoc } = document;
    downloadFile(encodedDoc, fileName);
  };

  const actionBtn = (_row, cell, _rowIndex) => (
    <div className="actionBox d-flex align-items-center">
      <Button variant="link" id={cell.ticketNo} onClick={() => handleDownload(cell.documentNo)}>
        <img src={process.env.REACT_APP_PUBLIC_URL + 'images/users/download.svg'} id={cell.documentNo} alt="Download" />
      </Button>
    </div>
  );

  const fileSizeText = (_row, cell, _rowIndex) => <p>{formatFileSize(cell.size)}</p>;

  const emptyDataMessage = () =>
    !isLoading ? (
      <h6 className="text-center text-bold m-0 p-0">No document found</h6>
    ) : (
      <h6 className="text-center text-bold m-0 p-0">Loading ...</h6>
    );

  const columns = [
    {
      dataField: 'fileName',
      text: 'Name',
    },
    {
      dataField: 'size',
      text: 'Size',
      formatter: fileSizeText,
    },
    {
      dataField: 'type',
      text: 'Type',
    },
    {
      dataField: 'Download',
      text: 'Download',
      formatter: actionBtn,
      id: 'download',
    },
  ];

  useEffect(() => {
    fetchTicketDetails();
  }, [id]);

  return (
    <div className="wrapperBase">
      {showAlert && <Alerts variant={alertVarient} onClose={() => setShowAlert(false)} alertshow={alertMessage} />}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="titleHeader d-flex align-items-center justify-content-between">
            <div className="info d-flex align-items-center flexGap">
              <h6>Ticket # : {data.callNo} </h6>
              {nextPrevTicket.prevTicket !== null || nextPrevTicket.nextTicket !== null ? (
                <>
                  <Button
                    className="blueBtn text-center"
                    disabled={nextPrevTicket.prevTicket === null}
                    onClick={() => navigate(`/ticket/view/${nextPrevTicket.prevTicket}`)}
                  >
                    <img
                      width={20}
                      height={20}
                      src={process.env.REACT_APP_PUBLIC_URL + 'images/skip-backward.svg'}
                      alt="Previous Ticket"
                    />
                  </Button>
                  <Button
                    className="blueBtn text-center"
                    disabled={nextPrevTicket.nextTicket === null}
                    onClick={() => navigate(`/ticket/view/${nextPrevTicket.nextTicket}`)}
                  >
                    <img
                      width={20}
                      height={20}
                      src={process.env.REACT_APP_PUBLIC_URL + 'images/skip-forward.svg'}
                      alt="Next Ticket"
                    />
                  </Button>
                </>
              ) : null}
            </div>
            {data.callerEmail === localStorage.getItem('email') && (
              <Button
                className="buttonPrimary text-center"
                onClick={() => {
                  buttonTracker(gaEvents.NAVIGATE_EDIT_TICKET);
                  navigate(`/ticket/edit/${id}`);
                }}
              >
                <img src={process.env.REACT_APP_PUBLIC_URL + 'images/users/edit.svg'} alt="" className="pRight6" /> Edit
              </Button>
            )}
          </div>

          <div className="wrapperBase">
            <Form>
              <Form.Group className="mb-3 input-group ">
                <div className="input-container">
                  <Form.Label className="view-heading">Description</Form.Label>
                  <div className="text-align-justify" dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </Form.Group>
              <hr />
              <Form.Group className="mb-3 input-group">
                <div className="input-container col-6 pr-24">
                  <Form.Label className="view-heading">Site Name</Form.Label>
                  <div> {data.site} </div>
                </div>
                <div className="input-container col-6">
                  <Form.Label className="view-heading">Priority</Form.Label>
                  <div> {parseInt(data.priority, 10)} </div>
                </div>
              </Form.Group>
              <hr />
              <Form.Group className="mb-3 input-group">
                <div className="input-container col-6 pr-24">
                  <Form.Label className="view-heading">Status</Form.Label>
                  <div> {data.status} </div>
                </div>
                <div className="input-container col-6">
                  <Form.Label className="view-heading">Assigned To</Form.Label>
                  <div> {data.assignedTo} </div>
                </div>
              </Form.Group>
              <hr />
              <Form.Group className="mb-3 input-group">
                <div className="input-container col-6 pr-24">
                  <Form.Label className="view-heading">Solution Provided</Form.Label>
                  <div className="text-align-justify" dangerouslySetInnerHTML={{ __html: data.solutionProvided }} />
                </div>
                <div className="input-container col-6">
                  <Form.Label className="view-heading">Created By</Form.Label>
                  <div> {data.createdBy} </div>
                </div>
              </Form.Group>
              <hr />
              <Form.Group className="mb-3 input-group">
                <div className="input-container col-6 pr-24">
                  <Form.Label className="view-heading">Created Date</Form.Label>
                  <div> {data.createdDate} </div>
                </div>
                <div className="input-container col-6">
                  <Form.Label className="view-heading">Problem Code</Form.Label>
                  <div> {data.problem} </div>
                </div>
              </Form.Group>
              <hr />
              <Form.Group className="mb-3 input-group">
                <div className="input-container col-6 pr-24">
                  <Form.Label className="view-heading">Last Modified Date</Form.Label>
                  <div> {data.lastModifiedDate} </div>
                </div>
              </Form.Group>
              <hr />
            </Form>
            {/* <div className="mt-4 mb-2">
              <h5 className="mb-0">Dispatch Schedule</h5>
              <hr className="mt-1" style={{ width: '190px' }} />
            </div>
            <Form.Group className="mb-3 input-group">
              <div className="input-container col-6 pr-24">
                <Form.Label className="view-heading">Technician</Form.Label>
                <div>
                  {data.dispatchSchedule && data.dispatchSchedule.userId ? data.dispatchSchedule.userId : 'Not yet scheduled'}
                </div>
              </div>
              <div className="input-container col-6">
                <Form.Label className="view-heading">Start Date</Form.Label>
                <div> {data.dispatchSchedule && data.dispatchSchedule.startDate ? data.dispatchSchedule.startDate : ''} </div>
              </div>
            </Form.Group>
            <hr /> */}
            <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
              <h5 className="mb-0 focusable-div">Document List</h5>
            </div>
            <div className="tabelBase" data-test-id="usertable">
              <ToolkitProvider keyField="DocumentNo" data={ticketDocuments} columns={columns}>
                {(props) => (
                  <div className="tableBaseBox ticketTable">
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={ticketDocuments?.length > 10 ? paginationFactory({ sizePerPage: 10 }) : null}
                      noDataIndication={emptyDataMessage}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
            <div className="d-flex justify-content-md-start justify-content-sm-center justify-content-center editAction">
              <input
                className="buttonDefault text-center minHeight45"
                type="submit"
                onClick={() => {
                  buttonTracker(gaEvents.NAVIGATE_TICKETS_LIST);
                  navigate('/tickets');
                }}
                value="Back"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
